/* トップページのプラン選択の部分 */
<template>
  <div>
    <div class="plan">
      <Title first="料金プラン" second="SUBSCRIPTION PLAN" />
        <div class="prices">
          <div class="waku a">
            <div class="price One">
              <h3>LITE</h3>
              <ul class="list">
                <li><span class="number">-1-</span> ......月3着まで</li>
                <li><span class="number">-2-</span> .月2着まで交換</li>
              </ul>
              <h2>14900円<span class="priceSpan">/月</span></h2>
              <div @click="sub(one)" class="sub">SUBSCRIBE</div>
            </div>
          </div>
          <div class="waku b">
            <div class="price Two">
              <h3>BASIC</h3>
              <ul class="list">
                <li><span class="number">-1-</span> ..........月5着</li>
                <li><span class="number">-2-</span> 月3着まで交換</li>
              </ul>
              <h2>30000円<span class="priceSpan">/月</span></h2>
              <div @click="sub(two)" class="sub">SUBSCRIBE</div>
            </div>
          </div>
          <div class="waku c">
            <div class="price Three">
              <h3>PUREMIUM</h3>
              <ul class="list">
                <li><span class="number">-1-</span> ..........月8着</li>
                <li><span class="number">-2-</span> 月5着まで交換</li>
                <li><span class="number">-3-</span> .......優先情報</li>
              </ul>
              <h2>45000円<span class="priceSpan">/月</span></h2>
              <router-link to="/select">SUBSCRIBE</router-link>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Title from '@/components/parts/Title.vue'
import firebase from '@/firebase/firebase'
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'TopPlan',
  components: {
    Title
  },
  data () {
    return {
      currentUser: null,
      one: 'price_1JbMtxHtQajurxejSAFSMcHO', // priceId oneもtwoも
      two: 'price_1JbPWuHtQajurxejKjxLkSuP',
      db: null,
      dbTwo: null,
      load: 0,
      subscri: '',
      id: ''
    }
  },
  created () {
    this.db = firebase.firestore()
    this.dbTwo = firebase.firestore()
    this.checkData = firebase.firestore()
    this.confirm = firebase.firestore()
    this.userOrNot()
  },
  watch: {
    load: function () {
      if (this.currentUser !== null) {
        this.checkData
          .collection('customers')
          .doc(this.currentUser)
          .collection('checkout_session')
          .get()
          .then((querySnapshot) => {
            console.log(querySnapshot)
            querySnapshot.forEach((doc) => {
              console.log(doc)
              this.subscri = 'also'
            })
          })
        this.confirm
          .collection('customers')
          .doc(this.currentUser)
          .get()
          .then((doc) => {
            this.id = doc.data().stripeId
          })
      }
    }
  },
  methods: {
    userOrNot () {
      // ログインされてるかされてないかのコード
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          this.currentUser = firebaseUser.uid
        }
        this.load = 1
      })
    },
    async sub (content) {
      if (this.currentUser === null) {
        // ログインされていない場合ログイン画面に飛ぶ
        this.$router.push('/login')
      } else {
        if (this.subscri === 'also') {
          alert('登録済みです')
        } else {
          this.dbTwo
            .collection('customers')
            .doc(this.currentUser)
            .update({
              subscribe: 0,
              plan: content,
              id: this.currentUser
            })
          const stripePromise = loadStripe('pk_test_51JOduLHtQajurxeji1rWdL4BYuXPHswAsUte3MEO7tlPcV81rnEbbZGZSIxC1KLJ0KRQ6IJv32XB1ucrtcjB376000gwX0S2js')
          const selectedPrice = {
            price: content
          }
          selectedPrice.quantity = 1
          const checkoutSession = {
            automatic_tax: true,
            tax_id_collection: true,
            collect_shipping_address: true,
            allow_promotion_codes: true,
            line_items: [selectedPrice],
            success_url: 'http://localhost:8080/select',
            cancel_url: 'http://localhost:8080/service',
            metadata: {
              key: 'value'
            }
          }
          const docRef = await this.db
            .collection('customers')
            .doc(this.currentUser)
            .collection('checkout_session')
            .add(checkoutSession)
          docRef.onSnapshot(async (snap) => {
            const { error, url } = snap.data()
            const stripePrice = snap.data().line_items[0].price
            const stripeQuantity = snap.data().line_items[0].quantity
            const stripe = await stripePromise
            stripe.redirectToCheckout({
              lineItems: [{
                price: stripePrice,
                quantity: stripeQuantity
              }],
              mode: 'subscription',
              successUrl: 'http://localhost:8080',
              cancelUrl: 'http://localhost:8080/service'
            })
            if (error) {
              alert(`An error occured: ${error.message}`)
              console.log(error)
            }
            if (url) {
              window.location.assign(url)
              console.log(url)
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.prices {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}

.list {
  text-align: left;
}

.list li {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 12px;
  text-align: center;
  line-height: 17px;
}

.number {
  text-align: center;
}

.prices ul {
  margin-top: 40px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  line-height: 35px;
  list-style: none;
  padding-left: 0;
  height: 90px;
}

.prices a,
.sub {
  border: 1px solid black;
  text-decoration: none;
  color: black;
  display: block;
  width: 150px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 2px;
  box-shadow: 5px 5px 10px rgb(56, 56, 56);
  cursor: pointer;
}

.waku {
  display: flex;
  align-items: center;
}

.a {
  background-image: url("~@/assets/top/plan/lite.jpg");
  background-position: center;
  background-size: cover;
}

.b {
  background-image: url("~@/assets/top/plan/basic.jpg");
  background-position: center;
  background-size: cover;
}

.c {
  background-image: url("~@/assets/top/plan/premium.jpg");
  background-size: cover;
  background-position: center;
}

.price {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  border: 4px solid rgb(245, 245, 245);
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
}

.price:before,
.price:after {
  position: absolute;
  display: block;
  height: 0;
  content: '';
}

.price:after {
  top: -2px;
  border-top: solid 100px transparent;
  right: -2px;
}

.price:before {
  right: 0px;
  top: 0px;
  z-index: 2;
}

.price h2 {
  text-align: center;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px black;
}

.price h3 {
  font-weight: bold;
  text-align: center;
  transform: rotate(45deg);
  position: absolute;
  z-index: 999;
  font-size: 15px;
}

.priceSpan {
  font-weight: lighter;
  font-size: 15px;
  margin-left: 10px;
}

.plan {
  background-color: rgb(231, 231, 231);
}

.pTitle h2 {
  text-align: center;
  padding-top: 50px;
}

.pTitle p {
  text-align: center;
}

@media screen and (max-width: 950px) {
  .plan {
    margin-top: 200px;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .prices {
    overflow-x: auto;
    white-space: nowrap;
  }

  .waku {
    width: 100%;
    margin-right: 35px;
    height: 380px;
  }

  .price:before,
  .price:after {
    border-left: solid 70px transparent;
  }

  .price:before {
    border-top: solid 70px rgb(123, 199, 189);
  }

  .price h2 {
    letter-spacing: 3px;
    font-size: 25px;
    margin-bottom: 70px;
    width: 280px;
  }

  .price h3 {
    font-size: 10px;
  }

  .One h3 {
    right: 12px;
    top: 6px;
  }

  .Two h3 {
    right: 7px;
    top: 7px;
  }

  .Three h3 {
    right: -3px;
    top: 8px;
  }

  .price {
    height: 320px;
  }
}

@media screen and (min-width: 951px) {
  .plan {
    margin-top: 350px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .waku {
    width: 30%;
    height: 420px;
  }

  .price:before,
  .price:after {
    border-left: solid 100px transparent;
  }

  .price:before {
    border-top: solid 100px rgb(123, 199, 189);
  }

  .price h2 {
    letter-spacing: 5px;
    font-size: 32px;
    margin-bottom: 70px;
  }

  .price h3 {
    font-size: 15px;
  }

  .One h3 {
    right: 20px;
    top: 10px;
  }

  .Two h3 {
    right: 10px;
    top: 10px;
  }

  .Three h3 {
    right: -2px;
    top: 12px;
  }

  .price {
    height: 350px;
  }
}
</style>
