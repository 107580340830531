/* トップページのバナーの下のサービス部分 */
<template>
  <div class="service">
    <div class="description">
      <Title first="メンバー特典" second="MEMBERSHIP PERKS" />
        <div class="dList">
          <div class="dItem">
            <div class="icon">
              <img src="@/assets/top/service/full.svg" class="全ての服アイコン" />
            </div>
            <div>
              <h3>
                <span class="black">全</span>ての服を利用する事が可能<br>
                <span class="little">Full Service Fashion</span>
              </h3>
              <p>
                綺麗にクリーニングされた状態であなたの元に届き、すぐに利用可能。
              </p>
            </div>
          </div>
          <div class="dItem color">
            <div class="icon">
              <img src="@/assets/top/service/sales.svg" class="セールアイコン" />
            </div>
            <div>
              <h3>
                <span class="black">メ</span>ンバーのみセール価格で購入可能<br>
                <span class="little">Members-Only Sales</span>
              </h3>
              <p>
                本当に欲しい服が見つかり、所有したい方はすぐに購入可能。
              </p>
            </div>
          </div>
          <div class="dItem">
            <div class="icon">
              <img src="@/assets/top/service/flexibility.svg" class="柔軟性アイコン" />
            </div>
            <div>
              <h3>
                <span class="black">総</span>合的な柔軟性<br>
                <span class="little">Total Flexibility</span>
              </h3>
              <p>
                プラン内の服ならいつでも返品可能。<br>
                「好きな服を好きな時に」をコンセプトにサービスの展開。
              </p>
            </div>
          </div>
          <div class="dItem color">
            <div class="icon">
              <img src="@/assets/top/service/zero.svg" class="解約アイコン" />
            </div>
            <div>
              <h3>
                <span class="black">い</span>つでも解約可能<br>
                <span class="little">Zero Commitments</span>
              </h3>
              <p>
                <span class="red">
                  違約金無しに(※最低継続利用期間の3ヶ月は除く)<br>
                </span>
                追加料金なし、いつでもメンバーシップを一時停止またはキャンセルできる。
              </p>
            </div>
          </div>
          <div class="dItem">
            <div class="icon">
              <img src="@/assets/top/service/size.svg" class="フィットアイコン" />
            </div>
            <div>
              <h3>
                <span class="black">フ</span>ィットに関するよりスマートなヒント<br>
                <span class="little">Smarter Tips on Fit</span>
              </h3>
              <p>
                サイズ表記の細分化。より自身に合ったサイズで好みの服を見つけよう。
              </p>
            </div>
          </div>
          <div class="dItem color">
            <div class="icon">
              <img src="@/assets/top/service/always.svg" class="選べるアイコン" />
            </div>
            <div>
              <h3>
                <span class="black">常</span>にあなた自身が選べる<br>
                <span class="little">Items You Choose, Always</span>
              </h3>
              <p>
                好きでもない服でいっぱいの箱を送るつもりはない。<br>
                あなたが実際に欲しい服をお選びください。
              </p>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Title from '@/components/parts/Title.vue'

export default {
  name: 'TopService',
  components: {
    Title
  }
}
</script>

<style scoped>
.service {
  background-color: rgb(245, 245, 245);
}

.dList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
}

.dItem {
  display: flex;
  align-items: center;
}

.dItem img {
  width: 40px;
}

.red {
  color: rgb(241, 49, 43);
  font-weight: bold;
}

.icon {
  position: relative;
  margin-right: 10px;
}

.icon:after {
  content: '';
  width: 35px;
  height: 35px;
  background-color: rgba(2, 155, 135, 0.212);
  position: absolute;
  bottom: 0px;
  right: -5px;
  z-index: -1;
  border-radius: 30px;
}

.dItem h3 {
  margin: 0;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 18px;
}

.little {
  font-size: 10px;
  letter-spacing: 1px;
}

.black {
  background-color: rgb(61, 61, 61);
  color: white;
  border-radius: 50px;
  padding: 1px 4px;
}

@media screen and (min-width: 751px) {
  .service {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .dItem {
    width: 45%;
    margin-bottom: 50px;
  }

  .dItem h3 {
    line-height: 18px;
  }

  .dList {
    width: 75%;
  }
}

@media screen and (max-width: 750px) {
  .service {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .dItem {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .dItem h3 {
    text-align: left;
    line-height: 30px;
  }

  .dItem p {
    text-align: left;
    margin: 0;
  }

  .dList {
    width: 100%;
  }

  .color {
    background-color: white;
  }
}
</style>
