/* nicotesのトップの説明などのページ */
<template>
  <div class="home">
    <div class="homeImage">
    </div>
    <TopImage />
    <TopService />
    <TopPlan />
    <TopOther />
  </div>
</template>

<script>
import TopImage from '@/components/top/TopImage.vue'
import TopService from '@/components/top/TopService.vue'
import TopPlan from '@/components/top/TopPlan.vue'
import TopOther from '@/components/top/TopOther.vue'
import firebase from '@/firebase/firebase'

export default {
  name: 'Home',
  components: {
    TopImage,
    TopService,
    TopPlan,
    TopOther
  },
  data () {
    return {
      db: null,
      currentUser: null
    }
  },
  created () {
    this.db = firebase.firestore()
    firebase.auth().onAuthStateChanged((user) => {
      this.currentUser = user.uid
      if (user) {
        this.address(user.uid)
      }
    })
  },
  methods: {
    address (user) {
      this.db
        .collection('customers')
        .doc(user)
        .get()
        .then((doc) => {
          if (doc.data().address === undefined) {
            this.$router.push('/address')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.homeImage {
  background-image: url("~@/assets/top/topimage/top.jpg");
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-position: center;
}
</style>
