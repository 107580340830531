<template>
  <div class="check" @click="change()">
    <span class="burger inner" :class="{ changeThree: menu }"></span>
  </div>
  <div id="nav" :class="{ change: menu }" class="changeFirst">
    <ul>
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/select">About</router-link>
      </li>
      <li @click="logout()">ログアウト</li>
    </ul>
  </div>
  <div :class="{ changeTwo: menu }" class="changeSecond">
    <router-view/>
    <div class="footer">
      <div>
        <p>
          Rent Your Clothes. Change Your Life.<br>
          サスティナブル Buy less. Wear more.<br>
          <span class="littleWord">©️NICOTES.2021</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/firebase/firebase'

export default {
  data () {
    return {
      menu: false
    }
  },
  methods: {
    change () {
      this.menu = !this.menu
      document.body.classList.toggle('move')
    },
    logout () {
      firebase.auth().signOut()
    }
  }
}
</script>

<style lang="scss">
.move {
  overflow: hidden;
}

.burger {
  width: 100%;
  height: 3px;
  background-color: rgb(35, 35, 35);
  position: relative;
  transition: 0.5s;
}

.burger:before,
.burger:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  transition: 0.5s;
  left: 0;
}

@media screen and (max-width: 750px) {
  .burger:before {
    transform: translateY(-10px);
  }

  .burger:after {
    transform: translateY(10px);
  }

  .check {
    width: 38px;
    height: 38px;
    top: 15px;
    right: 20px;
  }

  .changeTwo {
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    -webkit-filter: blur(1px) brightness(0.5);
    filter: blur(1px) brightness(0.5);
  }
}

@media screen and (min-width: 751px) {
  .burger:before {
    transform: translateY(-16px);
  }

  .burger:after {
    transform: translateY(16px);
  }

  .check {
    width: 48px;
    height: 48px;
    top: 16px;
    right: 16px;
  }

  .changeTwo {
      -moz-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
      -webkit-filter: blur(1px) brightness(0.5);
      filter: blur(1px) brightness(0.5);
  }
}

.changeThree {
  transform: rotate(180deg);
  background-color: transparent;
}

.changeThree:before {
  transform: rotate(135deg);
  background-color: rgb(245, 245, 245);
}

.changeThree:after {
  transform: rotate(-135deg);
  background-color: rgb(245, 245, 245);
}

.check {
  position: fixed;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.changeFirst {
  transition: 1.0s;
}

.changeSecond {
  transition: 1.0s;
}

.change {
  -moz-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.footer {
  background-color: rgb(15, 15, 15);
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-weight: bold;
  color: rgb(245, 245, 245);
}

.littleWord {
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 12px;
}

@media screen and (min-width: 951px) {
  .footer p {
    letter-spacing: 3px;
  }

  #nav {
    width: 25vw;
  }
}

@media screen and (max-width: 950px) {
  .footer p {
    font-size: 15px;
    letter-spacing: 2px;
  }

  #nav {
    width: 85vw;
  }
}

body::-webkit-scrollbar {
  display: none;
}

#nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  background-color: rgb(25, 25, 25);
  overflow-y: scroll;
  z-index: 2;
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -ms-overflow-style: none;
  padding-top: 60px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#nav::-webkit-scrollbar {
  display: none;
}
</style>
