/* トップページの他の部分/サービスの使い方に飛ぶとか、会社概要とか */
<template>
  <div class="other">
    <div class="base">
      <div class="service">
        <router-link to="/service">
          <h2>サービスの使い方<br><span class="little">HOW IT WORKS</span></h2>
        </router-link>
      </div>
      <div class="company">
        <router-link to="/company">
          <h2>会社概要<br><span class="little">COMPANY PROFILE</span></h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopOther'
}
</script>

<style scoped>
.other a {
  text-decoration: none;
}

.little {
  font-weight: normal;
  font-size: 10px;
}

h2 {
  margin: 0;
}

.service {
  background-color: white;
  border: 1px solid black;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service:before,
.service:after {
  content: '';
  width: 3px;
  height: 50px;
  background-color: #161616;
}

.service:before {
  margin-right: 15px;
  transform: rotate(-35deg);
}

.service:after {
  margin-left: 15px;
  transform: rotate(35deg);
}

.company:before,
.company:after {
  content: '';
  width: 3px;
  height: 50px;
  background-color: rgb(245, 245, 245);
}

.company:before {
  margin-right: 35px;
  transform: rotate(-35deg);
}

.company:after {
  margin-left: 35px;
  transform: rotate(35deg);
}

.company {
  background-color: black;
  color: white;
  height: 100px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company h2 {
  color: white;
}

.service h2 {
  color: black;
}

.base {
  justify-content: center;
}

.other {
  width: 100%;
  background-color: rgb(245, 245, 245);
}

@media screen and (max-width: 950px) {
  h2 {
    letter-spacing: 1px;
    font-size: 15px;
  }

  .other {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .company {
    width: 100%;
  }

  .service {
    width: 100%;
    margin-bottom: 30px;
  }

  .base {
    width: 300px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 951px) {
  h2 {
    letter-spacing: 5px;
  }

  .base {
    display: flex;
  }

  .company {
    width: 350px;
  }

  .service {
    width: 350px;
  }

  .other {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
</style>
