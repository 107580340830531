/* トップページの一番上の画像部分(バナー？) */
<template>
  <div>
    <div class="topImage">
      <div class="topBox">
        <h2>NICOTES.</h2>
        <p>地球にも優しく。<br>好きな服を好きな時に。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopImage'
}
</script>

<style scoped>
.topImage {
  position: relative;
  height: 450px;
  width: 100%;
}

.topBox h2 {
  position: absolute;
  letter-spacing: 4px;
  font-size: 33px;
  text-shadow: 3px 3px 3px rgb(245, 245, 245);
}

.topBox p {
  position: absolute;
  bottom: 40px;
  right: 25px;
  writing-mode: vertical-rl;
  letter-spacing: 3px;
  text-align: left;
  line-height: 30px;
  text-shadow: 1px 1px 3px rgb(245, 245, 245);
}

@media screen and (min-width: 951px) {
  .topBox h2 {
    top: 35px;
    left: 50px;
  }
}

@media screen and (max-width: 950px) {
  .topBox h2 {
    top: 15px;
    left: 30px;
  }
}
</style>
