/* タイトルパーツ */
<template>
  <div>
    <div class="title">
      <p>{{ second }}</p>
      <h2>{{ first }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    first: {
      type: String,
      default: '',
      required: true
    },
    second: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>

<style scoped>
.title h2 {
  text-align: center;
  font-family: 'M+ Type-2 (general-j) Regular';
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title h2:before,
.title h2:after {
  border-top: 1px solid gray;
  content: '';
}

.title h2:before {
  margin-right: 1em;
}

.title h2:after {
  margin-left: 1em;
}

.title p {
  font-weight: lighter;
  letter-spacing: 4px;
}

.title {
  margin-bottom: 90px;
}

@media screen and (min-width: 751px) {
  .title h2 {
    letter-spacing: 10px;
    font-size: 30px;
  }

  .title h2:before,
  .title h2:after {
    width: 4.5em;
  }

  .title p {
    font-size: 12px;
  }
}

@media screen and (max-width: 750px) {
  .title h2 {
    letter-spacing: 4px;
    font-size: 23px;
  }

  .title h2:before,
  .title h2:after {
    width: 2em;
  }

  .title p {
    font-size: 9px;
  }
}
</style>
