import firebase from 'firebase'

const settings = { timestampsInSnapshots: true }

const firebaseConfig = {
  apiKey: 'AIzaSyDM-CKAj-fKCUFsdwhsTrb5BH7TILoNB1o',
  authDomain: 'nicotes-8cdf6.firebaseapp.com',
  databaseURL: 'https://nicotes-8cdf6-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'nicotes-8cdf6',
  storageBucket: 'nicotes-8cdf6.appspot.com',
  messagingSenderId: '1090782076375',
  appId: '1:1090782076375:web:9e2b1f0e2c1252c0ae3f92',
  measurementId: 'G-PE476H8E57'
}
firebase.initializeApp(firebaseConfig)
firebase.firestore().settings(settings)
export default firebase
